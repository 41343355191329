<template>
  <v-container fluid class="px-0">
    <h2 class="mx-5">{{ title }}</h2>
    <v-slide-group
      :show-arrows="$vuetify.breakpoint.lgAndUp"
      dark
      next-icon="navigate_next"
      prev-icon="navigate_before"
    >
      <v-slide-item v-for="movie in movies" :key="movie.id" v-slot:default="{ active, toggle }" style="width: 150px; height: auto;">
        <Movie :active="active" :action="toggle" :movie="movie" class="mx-1"></Movie>
      </v-slide-item>
    </v-slide-group>
  </v-container>
</template>

<script>
import Movie from './Movie';
export default {
  components: { Movie },
  data: () => ({
    model: null
  }),
  props: {
    movies: Array,
    title: String
  }
};
</script>

<style>
</style>
