import '@mdi/font/css/materialdesignicons.css'; // Ensure you are using css-loader
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import en from 'vuetify/lib/locale/en';
import fr from 'vuetify/lib/locale/fr';
// import VueI18n from 'vue-i18n';

Vue.use(Vuetify);
/*
Vue.use(VueI18n);

const messages = {
  en: {
    $vuetify: {
      dataIterator: {
        rowsPerPageText: 'Items per page:',
        pageText: '{0}-{1} of {2}'
      }
    }
  },
  fr: {
    $vuetify: {
      dataIterator: {
        rowsPerPageText: 'Elements par page:',
        pageText: '{0}-{1} sur {2}'
      }
    }
  }
};

let i18n = new VueI18n({
  locale: 'en',
  messages
});
*/

export default new Vuetify({
  theme: {
    dark: true
  },
  icons: {
    iconfont: 'mdi'
  },
  lang: {
    locales: { en, fr }
    // t: (key, ...params) => i18n.t(key, params)
  }
});
