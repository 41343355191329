<template>
  <div>
    <v-navigation-drawer v-model="drawer" :clipped="$vuetify.breakpoint.lgAndUp" dark fixed app>
      <v-list dense>
        <template v-for="item in items">
          <v-layout v-if="item.heading" :key="item.heading" row align-center>
            <v-flex xs6>
              <v-subheader v-if="item.heading">{{ item.heading }}</v-subheader>
            </v-flex>
            <v-flex xs6 class="text-xs-center">
              <a href="#!" class="body-2 black--text">EDIT</a>
            </v-flex>
          </v-layout>
          <v-list-group
            v-else-if="item.children"
            :key="item.text"
            v-model="item.model"
            :prepend-icon="item.model ? item.icon : item['icon-alt']"
          >
            <template v-slot:activator>
              <v-list-item class="pl-0">
                <v-list-item-content>
                  <v-list-item-title>{{ item.text }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <v-list-item v-for="(child, i) in item.children" :key="i" :to="child.to" class="ml-6">
              <v-list-item-action v-if="child.icon">
                <v-icon>{{ child.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>{{ child.text }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
          <v-list-item v-else-if="item.to" :key="item.text" :to="item.to">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-else-if="item.click" :key="item.text" @click="item.click">
            <v-list-item-action>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <v-list-item @click="logout">
          <v-list-item-action>
            <v-icon>exit_to_app</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" color="green darken-3" app fixed dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer">
        <v-icon>menu</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title class="hidden-sm-and-down" style="width: 300px">
        <span>
          <v-avatar size="32px" tile>
            <img src="/turnip.svg" alt="navet"/>
          </v-avatar>
          Le Navet
        </span>
      </v-toolbar-title>
      <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>
      <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>
      <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>
      <ais-instant-search :search-client="searchClient" index-name="movies" >
        <ais-configure
          :hitsPerPage="10"
          :restrictSearchableAttributes="['title']"
        />
        <ais-autocomplete>
          <template v-slot="{ indices, refine }" >
            <v-autocomplete
              flat
              solo-inverted
              v-model="model"
              @change="change($event, refine)"
              :items="indices[0].hits"
              @update:search-input="searchInput($event, refine)"
              no-filter
              hide-no-data
              hide-details
              item-text="title"
              placeholder="Start typing to Search"
              prepend-inner-icon="search"
              return-object
            >
              <template v-slot:item="data">
                <v-list-item-avatar tile left>
                  <img v-lazy="imgObj(data.item.poster_path)">
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ data.item.title }}</v-list-item-title>
                  <v-list-item-subtitle>{{ data.item.overview.slice(0, Math.min(data.item.overview.length, 100)) }}</v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </template>
        </ais-autocomplete>
      </ais-instant-search>

      <!--
      <v-menu
        left
        bottom
      >
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            {{ $vuetify.lang.current }}
          </v-btn>
        </template>

        <v-list>
          <v-list-item-group v-model="$vuetify.lang.current">
            <v-list-item
              v-for="(lang, locale) in $vuetify.lang.locales"
              :key="locale"
              :value="locale"
            >
              <v-list-item-title>Option {{ locale }}</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
      -->
    </v-app-bar>

    <v-main>
      <slot/>
    </v-main>
  </div>
</template>

<script>
import algoliasearch from 'algoliasearch/lite';
import 'instantsearch.css/themes/algolia-min.css';
import firebase from 'firebase/app';
import 'firebase/auth';

export default {
  name: 'Layout',
  data: function () {
    return {
      searchClient: algoliasearch(
        '99XRH5RMXW',
        '7f933f63c0f9fcbbab2718ceb8addd60'
      ),
      logout: () => {
        firebase.auth().signOut().then(() => {
          this.$router.push('login');
        }).catch((error) => {
          // An error happened.
          console.log(error);
        });
      },
      isLoading: false,
      model: null,
      drawer: null,
      items: [
        { icon: 'mdi-home', text: 'Home', to: '/' },
        { icon: 'mdi-movie', text: 'Browse Movies', to: '/browse' },
        { icon: 'mdi-shuffle', text: 'Random Movie', click: this.randomMovie },
        { icon: 'mdi-television', text: 'Browse Series', to: '/browsetv' },
        { icon: 'mdi-account', text: 'Account', to: '/account' },
        { icon: 'mdi-chart-line', text: 'Statistics', to: '/statistics' }
        /*
        {
          icon: 'keyboard_arrow_up',
          'icon-alt': 'keyboard_arrow_down',
          text: 'Administration',
          model: true,
          children: [
            { icon: 'local_movies', text: 'Movies' },
            { icon: 'tv', text: 'Series' },
            { icon: 'people', text: 'Users' },
            { icon: 'mail', text: 'Invitations' },
            { icon: 'bar_chart', text: 'Statistics' }
          ]
        }
        */
      ]
    };
  },
  methods: {
    searchInput (input, refine) {
      if (input || input === '') {
        refine(input);
      }
    },
    change (val, refine) {
      if (val) {
        this.$router.push({ name: 'movie', params: { id: val.id + '' } });
        this.model = null;
        refine('');
      }
    },
    imgObj (path) {
      return {
        src: 'https://assets.le-navet.com/static/300' + path,
        error: 'https://europe-west1-turnip-820de.cloudfunctions.net/importImage?image=' + path
      };
    },
    randomMovie: function () {
      let ids = [];
      this.$store.state.movies.forEach(movie => {
        ids.push(movie.id + '');
      });
      this.$router.push({ name: 'movie', params: { id: ids[Math.floor(Math.random() * ids.length)] } });
    }
  },
  created () {
    if (firebase.auth().currentUser) {
      this.$store.dispatch('getMovies');
    }
  }
}
</script>

<style scoped>

</style>
