<template>
  <v-card :color="active ? 'primary' : ''" class="d-flex" dark>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <img  v-on="on"
          @click="action"
          style="cursor: pointer; width: 100%;"
          v-lazy="imgObj"
        />
      </template>
      <span>{{movie.title}}</span>
    </v-tooltip>
    <div
      class="text-right"
      style="position:absolute; width: 100%;"
    >
      <v-btn text icon @click="updateWatched">
        <v-icon v-if="watch && watch.watched">mdi-checkbox-marked-circle-outline</v-icon>
        <v-icon v-if="!watch || !watch.watched">mdi-checkbox-blank-circle-outline</v-icon>
      </v-btn>
    </div>
    <div
      v-if="active"
      class="headline flex-grow-1 text-center align-self-center"
      style="position:absolute; width: 100%;"
    >
      <v-btn v-if="false" text icon :to="'/movie/'+movie.id">
        <v-icon size="100">play_circle_outline</v-icon>
      </v-btn>
      <v-btn block color="green darker-2" class="mt-5" :to="'/movie/'+movie.id">View</v-btn>
    </div>

    <v-progress-linear
      v-if="watch && watch.time && watch.time > 0"
      :value="(watch.time*100)/(watch.duration)"
      absolute
      bottom
      color="red"
    ></v-progress-linear>
  </v-card>
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/firestore';

export default {
  name: 'Movie',
  data () {
    return {
      watch: null,
      watchRef: null,
      watchSnapshot: null
    };
  },
  props: {
    movie: Object,
    action: Function,
    active: Boolean
  },
  computed: {
    imgObj: function () {
      return {
        src: 'https://assets.le-navet.com/static/300' + this.movie.poster_path,
        error: 'https://europe-west1-turnip-820de.cloudfunctions.net/importImage?image=' + this.movie.poster_path
      };
    }
  },
  created () {
    let firestore = firebase.firestore();
    this.watchRef = firestore.collection('users').doc(firebase.auth().currentUser.uid).collection('movie_watches').doc(this.movie.id + '');
    this.watchSnapshot = this.watchRef.onSnapshot(querySnapshot => {
      this.watch = querySnapshot.data();
    });
  },
  beforeDestroy: function () {
    if (this.watchSnapshot) {
      this.watchSnapshot();
    }
  },
  methods: {
    updateWatched () {
      if (!this.watch || !this.watch.watched) {
        this.watchRef.set({
          created: new Date(),
          modified: new Date(),
          watched: true
        });
      } else {
        this.watchRef.update({
          watched: false,
          time: 0
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
