<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  metaInfo: {
    title: 'Le Navet',
    titleTemplate: (titleChunk) => {
      // If undefined or blank then we don't need the hyphen
      return titleChunk ? `${titleChunk} - Le Navet` : 'Le Navet';
    }

  },
  components: {},
  data: function () {
    return {};
  }
};
</script>
