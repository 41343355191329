import Vue from 'vue';
import vuetify from '@/plugins/vuetify';
import App from './App.vue';
import router from './router';
import './registerServiceWorker';
import store from './store/store';
import VueLazyload from 'vue-lazyload';
import VueMeta from 'vue-meta';
import InstantSearch from 'vue-instantsearch';
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/performance';
// import VueI18n from 'vue-i18n';

Vue.use(VueLazyload, {
  preLoad: 1.3,
  loading: '/img/loading.gif',
  attempt: 1
});
Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
});

Vue.use(InstantSearch);

Vue.config.productionTip = false;

firebase.initializeApp({
  apiKey: 'AIzaSyAGJb0pU9En7DVGaf-qkzJI9sxnorN4r_A',
  authDomain: 'le-navet.com',
  databaseURL: 'https://turnip-820de.firebaseio.com',
  projectId: 'turnip-820de',
  // storageBucket: 'turnip-820de.appspot.com',
  messagingSenderId: '194814543716',
  appId: '1:194814543716:web:7b69ad5025dbd5a1'
});
firebase.performance();
firebase.firestore().enablePersistence({
  synchronizeTabs: true
});

/*
Vue.use(VueI18n);

const messages = {
  en: {
    menu: {
      home: 'Home'
    },
    $vuetify: {
      dataIterator: {
        rowsPerPageText: 'Items per page:',
        pageText: '{0}-{1} of {2}'
      }
    }
  },
  fr: {
    $vuetify: {
      dataIterator: {
        rowsPerPageText: 'Elements par page:',
        pageText: '{0}-{1} sur {2}'
      }
    }
  }
};

let i18n = new VueI18n({
  locale: 'en',
  messages
});
*/

new Vue({
  vuetify,
  store,
  router,
  // i18n,
  render: h => h(App)
}).$mount('#app');

// eslint-disable-next-line no-undef
gtag('event', 'page_view', { 'page_title': router.currentRoute.name, 'page_path': router.currentRoute.path });
