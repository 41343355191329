import Vue from 'vue';
import Router from 'vue-router';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import Home from './views/Home';

Vue.use(Router);

let router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '*',
      component: () => import('./views/Error.vue')
    },
    {
      path: '/',
      name: 'home',
      meta: {
        requiresAuth: true
      },
      component: Home
    },
    {
      path: '/account',
      name: 'account',
      meta: {
        requiresAuth: true
      },
      component: () => import('./views/Account.vue')
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('./views/Login.vue')
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('./views/Register.vue')
    },
    {
      path: '/playmovie/:id',
      name: 'playmovie',
      meta: {
        requiresAuth: true
      },
      component: () => import('./views/MoviePlayer.vue')
    },
    {
      path: '/playtv/:id/:season/:episode',
      name: 'playtv',
      meta: {
        requiresAuth: true
      },
      component: () => import('./views/TvPlayer.vue')
    },
    {
      path: '/movie/:id',
      name: 'movie',
      meta: {
        requiresAuth: true
      },
      component: () => import('./views/Movie.vue')
    },
    {
      path: '/people/:id',
      name: 'people',
      meta: {
        requiresAuth: true
      },
      component: () => import('./views/People.vue')
    },
    {
      path: '/browse',
      name: 'browse',
      meta: {
        requiresAuth: true
      },
      component: () => import('./views/Browse.vue')
    },
    {
      path: '/browsetv',
      name: 'browsetv',
      meta: {
        requiresAuth: true
      },
      component: () => import('./views/BrowseTv.vue')
    },
    {
      path: '/tv/:id',
      name: 'tv',
      meta: {
        requiresAuth: true
      },
      component: () => import('./views/Tv.vue')
    },
    {
      path: '/tv/:id/:season',
      name: 'season',
      meta: {
        requiresAuth: true
      },
      component: () => import('./views/Season.vue')
    },
    {
      path: '/statistics',
      name: 'statistics',
      meta: {
        requiresAuth: true
      },
      component: () => import('./views/Statistics.vue')
    }
  ]
});

router.beforeEach((to, from, next) => {
  firebase.auth().onAuthStateChanged((user) => {
    if ((to.name === 'register' || to.name === 'login') && user) {
      next('/');
    }
    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (user) {
        let userRef = firebase.firestore().collection('users').doc(user.uid);
        userRef.onSnapshot(snapshotQuery => {
          let userDetails = snapshotQuery.data();
          if (userDetails.enabled === true || to.name === 'account') {
            next();
          } else {
            next('/account');
          }
        });
      } else {
        next({
          path: '/login',
          params: { nextUrl: to.fullPath }
        });
      }
    } else {
      next();
    }
  });
});

router.afterEach((to, from) => {
  // eslint-disable-next-line no-undef
  gtag('event', 'page_view', { 'page_title': to.name, 'page_path': to.path });
});

export default router;
