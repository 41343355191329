<template>
  <layout>
    <v-container fluid class="px-0">
      <MovieRow v-if="$store.getters.watches" :movies="$store.getters.watches" title="Continue Watching"/>
      <MovieRow v-if="$store.getters.latestAddedMovies" :movies="$store.getters.latestAddedMovies"
                title="Latest Added"/>
      <MovieRow v-if="$store.getters.topRated" :movies="$store.getters.topRated" title="Top Rated"/>
      <MovieRow v-if="$store.getters.popular" :movies="$store.getters.popular" title="Popular"/>
      <MovieRow v-if="$store.getters.latestMovies" :movies="$store.getters.latestMovies" title="Latest Releases"/>
    </v-container>
  </layout>
</template>

<script>
import Layout from '../Layout';
import MovieRow from '../components/MovieRow';

export default {
  metaInfo: {
    title: 'Home'
  },
  data () {
    return {};
  },
  components: {
    MovieRow,
    Layout
  },
  created: function () {
    this.$store.dispatch('getMovies');
    this.$store.dispatch('getWatches');
  }
};
</script>
