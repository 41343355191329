import Vue from 'vue';
import Vuex from 'vuex';
import _ from 'lodash';
import firebase from 'firebase/app';
import 'firebase/firestore';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    genres: [],
    movies: [],
    watches: [],
    movieDetails: []
  },
  getters: {
    movies: state => {
      return state.movies;
    },
    movieDetails: state => {
      return state.movieDetails;
    },
    watches: state => {
      return _.slice(_.orderBy(state.watches, movie => movie.watch.updatedAt, ['asc']), 0, 20);
    },
    latestAddedMovies: state => {
      return _.slice(_.orderBy(state.movies, ['date_added'], ['desc']), 0, 20);
    },
    topRated: state => {
      return _.slice(_.orderBy(state.movies, ['vote_average'], ['desc']), 0, 20);
    },
    popular: state => {
      return _.slice(_.orderBy(state.movies, ['popularity'], ['desc']), 0, 20);
    },
    latestMovies: state => {
      return _.slice(_.orderBy(state.movies, ['release_date'], ['desc']), 0, 20);
    },
    upcoming: state => {
      return _.slice(_.orderBy(state.movies, ['release_date'], ['desc']), 0, 20);
    }
  },
  mutations: {
    setMovies (state, payload) {
      state.movies = payload;
    },
    setWatches (state, payload) {
      state.watches = payload;
    },
    addWatch (state, payload) {
      state.watches.push(payload);
    }
  },
  actions: {
    async getMovies ({ state, commit }, plan) {
      if (state.movies.length > 0) {
        return;
      }
      try {
        let movieRef = firebase.firestore().collection('movies');
        movieRef.get().then(snapshot => {
          if (snapshot.empty) {
            console.log('No matching documents.');
            return;
          }

          let movies = [];

          snapshot.forEach(doc => {
            let movie = doc.data();
            movies.push(movie);
          });

          commit('setMovies', movies);
        });
      } catch (error) {
        console.log(error);
        commit('setMovies', []);
      }
    },
    async getWatches ({ state, commit }, plan) {
      if (state.watches.length > 0) {
        return;
      }
      try {
        let watchesRef = firebase.firestore().collection('users').doc(firebase.auth().currentUser.uid).collection('movie_watches').where('watched', '==', false);
        watchesRef.get().then(snapshot => {
          if (snapshot.empty) {
            console.log('No matching documents.');
            return;
          }
          commit('setWatches', []);

          snapshot.forEach(doc => {
            firebase.firestore().collection('movies').doc(doc.id).get().then(snapshot2 => {
              let movie = snapshot2.data();
              if (movie) {
                movie.watch = doc.data();
                commit('addWatch', movie);
              } else {
                doc.ref.delete();
              }
            });
          });
        });
      } catch (error) {
        console.log(error);
        commit('setWatches', []);
      }
    }
  }
});
